.red-link {
    color: #f05d29;
    font-weight: 600;
    text-decoration:none;
}
.red-link:hover {
    color: #f05d29;
    cursor: pointer;
    text-decoration: underline;
}

.white-link {
    color: #ffffff;
    text-decoration:none;
}
.white-link:hover {
    color: #ffffff
}

._2R-kh {
    display: flex;
    flex-wrap: wrap;
    align-items: center !important;
    justify-content: center !important;
}
.row-block{
    align-items: center;
    text-align: center;
    line-height: 3;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;

}

.table-converence-agenda-first thead{
    background-color: #00a3cc;
    border: 1px solid #00a3cc;
    color: #ffffff;
}
.table-converence-agenda-second thead{
    background-color: #f05d29;
    border: 1px solid #f27549;
    color: #ffffff;
}
.table-converence-agenda th, td{
    text-align: center;
    font-size: 1em;
    font-weight: 600;
    vertical-align: middle;
    height: 70px;
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

@media only screen and (max-width: 768px) {
    .table-converence-agenda th, td{
        font-size: 0.8em;
    }
  }